<style scoped>
.detailCon {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin-top: 40px;
  background-color: #fff;
}
p {
  margin-bottom: 0;
}
.detailCon p {
  width: 100%;
  font-size: 2rem !important;
}
.back {
  position: fixed;
  top: 0;
  padding: 0 20px;
  width: 100%;
  height: 3rem;
  /* background: var(--searchColor); */
  background: white;
  color: #aaa;
  line-height: 3rem;
  font-size: 1.2rem;
  box-shadow: 0px 3px 3px rgba(30, 155, 204, 0.05);
  display: flex;
}
.columnView {
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px 0px 9px 0 rgba(29, 32, 33, 0.28);
  width: 100%;
  z-index: 400;
  line-height: 3.5rem;
  background: #fff;
  font-size: 1rem;
  color: #9e9e9e;
  font-size: 1.1rem;
  transition: all 0.5s ease 0s;
  font-family: "微软雅黑";
}
.view_input {
  background: rgba(199, 210, 214, 0.2);
  vertical-align: middle;
  display: inline-block;
  width: 80%;
  line-height: 2rem;
  border-radius: 50px;
  padding-left: 0.3125rem;
  margin-left: 0.46875rem;
  color: #111;
  padding: 0 10px;
  outline: none;
}
.publish {
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  color: #9e9e9e;
}
.viewContent {
  border-top: 1px solid #cccccc;
  margin-top: 20px;
  margin-bottom: 2rem;
  padding: 10px;
}
.viewContent .view_header {
  padding: 3px;
  text-align: center;
  color: #009688;
}
.viewContent .viewCir {
  margin-top: 10px;
  box-shadow: 0px 0px 20px 0px #e8e8e8;
  padding: 10px;
}
.viewContent .view_time {
  margin-top: 5px;
  width: 100%;
}
.viewContent .view_con {
  width: 100%;
  text-align: left;
}
</style>

<template>
  <div class="detailCon">
    <div class="back">
      <div style="text-align: left">
        <span style="display: inline-block" @click="back">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </span>
      </div>
    </div>
    <div style="color: #333; padding: 23px 18px 0px">
      <h3 style="font-size: 20px">{{ detailData.title }}</h3>
    </div>
    <div style="display: flex">
      <div style="height: 50%; color: #aaa; padding: 10px 18px 10px">
        <span style="color: #aaa">{{ detailData.time }}</span>
      </div>
    </div>

    <div
      style="padding: 23px 18px; font-size: 16px !important; background: white"
      class="showHtml"
      v-html="htmlData"
    ></div>
  </div>
</template>

<script>
import debug from "debug";
import { wxConPost, getUserPm } from "../../api/httpApi";
import moment from "moment";

moment.locale("zh-cn");
const _d = debug("@pages:messageDetail");
const _ = require("lodash");
const cheerio = require("cheerio");

export default {
  name: "messageDetail",
  data() {
    return {
      title: "",
      htmlData: "",
      detailData: {},
      userInfo: {},
    };
  },
  methods: {
    // 截取字符串
    getQueryString(name) {
      const url = window.location.href;
      const r = url.match(
        // eslint-disable-next-line
        new RegExp(`([\?|&])${name}=([^|&||#|\/]*)(&|#|\/|$)`)
      );
      if (r !== null) {
        const str = decodeURI(r[2]);
        const pos = str.indexOf("#/");
        if (pos >= 0) {
          return str.substr(0, pos);
        }
        debug("str====", str);
        return str;
      }
      return null;
    },
    // 获取内容详情
    getViewData() {
      // _cid: this.contentId
      // 兼容 _cid 数据库类型存储 Double 和 Srting 的类型问题
      const regex = /^[a-zA-Z]+$/;
      let query = {
        _cid: this.contentId,
      };
      wxConPost("/services/gttt-content-mgt-get/wxContent/contentDetail", query)
        .then((result) => {
          console.log("result==", result);
          let htmlData = this.fliterHtml(result.conHtml.content);
          // 匹配特殊字符
          if (this.userInfo && htmlData.indexOf("xx") > -1) {
            const reg = new RegExp("xx", "g");
            htmlData = htmlData.replace(reg, this.userInfo.name);
          }
          if (this.userInfo && htmlData.indexOf("/static/gtttueditor/newresource") > -1) {
            const reg = new RegExp("/static/gtttueditor/newresource", "g");
            htmlData = htmlData.replace(reg, "https://gt-wx.gtrmt.cn/static/gtttueditor/newresource");
          }
          const $ = cheerio.load(htmlData);

          // if ($("video")) {
          //   $("video").attr("poster", result.conHtml.img[0]);
          // }
          this.htmlData = $.html();
          this.detailData = result.conHtml;
          if (this.userInfo && this.detailData.title.indexOf("xx") > -1) {
            const reg = new RegExp("xx", "g");
            this.detailData.title = this.detailData.title.replace(
              reg,
              this.userInfo.name
            );
          }
          if (this.detailData.updated) {
            if (
              moment(this.detailData.updated).format("YYYY") ==
              moment().format("YYYY")
            ) {
              this.detailData.time = moment(this.detailData.updated).format(
                "MM月DD日"
              );
            } else {
              this.detailData.time = moment(this.detailData.updated).format(
                "YYYY年MM月DD日"
              );
            }
          }
        })
        .catch((err) => {
          _d("Err get video in db", err);
        });
    },
    // 处理Html
    fliterHtml(html) {
      var style_regx = /<style.*?>.*?<\/style>/g;
      var body_rgex = /<body.*?>(.*?(\n))*.*?<\/body>/;
      var cnt_regx = /([\}|\>][\.\+\>\,\w\ \*]+\{)/g;
      // 获得样式列表
      var styleArr = html.match(style_regx);
      if (_.isEmpty(styleArr)) {
        return html;
      } else {
        // 处理样式表
        styleArr.map(function (style, key) {
          let styleList = style.match(cnt_regx);
          styleList.forEach(function (v, k) {
            let cnt_replace = v;
            if (k === 0) {
              cnt_replace = ">" + ".showHtml " + v.substring(1);
            } else {
              cnt_replace = "}" + ".showHtml " + v.substring(1);
            }
            cnt_replace = cnt_replace.replace(/\,/, ",.showHtml ");
            styleArr[key] = styleArr[key].replace(v, cnt_replace);
          });
        });
        var body = html.match(body_rgex);
        debug("111111", body);
        if (!_.isEmpty(body)) {
          body = body[0].replace(/((\<body.*?\>)|(\<\/body\>))/g, "");
          debug("body--------", body);
        } else {
          body = "";
        }
        return html + styleArr.join("") + body;
      }
    },
    // 回退
    back() {
      this.$router.back(-1);
    },
  },
  components: {},
  created() {
    this.contentId = this.getQueryString("id");
    if (this.contentId) {
      this.getViewData();
    }
  },
  filters: {
    dateFormat(time) {
      return moment(time).startOf("minutes").fromNow();
    },
  },
};
</script>
